import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert2'; 
import { useNavigate } from 'react-router-dom'; 

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CategoryButton = styled.button`
  margin: 10px;
  padding: 3%;
  border: none;
  border-radius: 10px;
  background-color: ${({ selected }) => (selected ? '#37474F' : 'transparent')};
  color: ${({ selected }) => (selected ? '#fff' : '#8A8E90')};
  cursor: pointer;
  outline: none;
`;

const PreferenceSelection = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const id_user = localStorage.getItem('id_user');
  const navigate = useNavigate(); 

  const searchCategorias = () => {
    var data = {
      id_user:1,
      "type":"GetPreferencias"
    };
    fetch('https://novomediaapp.azurewebsites.net/puntoLibre.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          navigate('/MisPublicaciones');
        } else {
          console.error('Error al obtener las categorias:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getCategorias = () => {
    fetch('https://novomediaapp.azurewebsites.net/GetCategorias.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setCategories(data.categorias);
        } else {
          console.error('Error al obtener las categorias:', data.message);
        } 
      })
      .catch(error => console.error('Error de red:', error));
  }

  const toggleCategory = (categoryId) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(categoryId)
        ? prevSelected.filter((selected) => selected !== categoryId)
        : [...prevSelected, categoryId]
    );
  };

  const handleSubmit = async () => {
    var data = {
      "selectedCategories": selectedCategories,
      id_user,
      "type":"GuardaPreferencias"
    };

    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/puntoLibre.php', {
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          navigate('/MisPublicaciones');
        } else {
          swal.fire('Error',result.message, 'error');
        }
      } else {
        swal.fire('Error', 'Error en la solicitud', 'error');
      }
    } catch (error) {
      swal.fire('Error', 'Error de red', 'error');
    }
  };

  useEffect(() => {
    getCategorias();
    searchCategorias();
  }, []);


  return (
    <div className="container mt-4" style={{marginTop:'20%',marginBottom:'20%',textAlign:'center'}}><br/><br/>
      <h1>¿Qué temas te interesan?<br/><br/></h1>
      <p>Selecciona los temas que deseas que te mostremos<br/><br/></p>
      <Container>
        {categories.map((category) => (
          <CategoryButton
            key={category.id}
            selected={selectedCategories.includes(category.id)}
            onClick={() => toggleCategory(category.id)}
          >
            {category.tipo}
          </CategoryButton>
        ))}
      </Container>
      <button style={{marginTop:'10%',padding:'5%'}} onClick={handleSubmit} className="btn btn-warning">Entrar</button>
    </div>
  );
};

export default PreferenceSelection;
