import React, { useState, useEffect } from 'react';
import logo from './imagenes/puntoLibre.png';
import colmena from './imagenes/colmena.png';
import { useNavigate } from 'react-router-dom';
import './DropdownMenu.css'; // Estilos para el menú desplegable

const TopBarDes = () => {
  const navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const organizacion = localStorage.getItem('organizacion');


  useEffect(() => {
    // Función para actualizar la fecha y hora actual cada segundo
    const updateDateTime = () => {
      const now = new Date();
      const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ];
      const formattedDate = `${now.getDate()} de ${months[now.getMonth()]} de ${now.getFullYear()}`;
      const formattedTime = `${now.getHours()}:${String(now.getMinutes()).padStart(2, '0')}`;
      setCurrentDateTime(`${formattedDate} - ${formattedTime}`);
    };

    // Actualizar la fecha y hora actual inicialmente y luego cada segundo
    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);
    console.log(organizacion);
    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
    
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const deembyReturn = () => {
    window.location.href = 'https://deemby.org';
  };

  return (
    <div style={styles.topBar}>
      <div style={{ ...styles.navContainer, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span style={styles.navText}>YouTube | Twitter | Instagram | X | Facebook  | LinkedIN | eMail</span>
        <img src={colmena} alt="Logo" style={{ height: '40px', width: 'auto', paddingRight: '2%', cursor: 'pointer' }} onClick={() => deembyReturn()} />
      </div>
      <div style={styles.logoContent}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {organizacion?
          <div className="menu-button" onClick={toggleMenu}>
            <p style={{ cursor: 'pointer', fontSize: '25px' }}>☰</p>
          </div>
        :''}
          <img src={logo} alt="puntoLibre" style={styles.logo} />
          <div style={{ marginLeft: 'auto', textAlign: 'right', paddingRight: '2%' }}>
            <div>{currentDateTime}</div>
            <div><b>País: </b>México</div>
          </div>
        </div>
      </div>
      
      <div className={`menu-container ${menuOpen ? 'show' : ''}`}>
        <div className="close-menu" style={{ marginLeft: '90%', cursor: 'pointer' }} onClick={toggleMenu}>✖</div>
        <ul className="menu">
          <li onClick={() => { navigate('/'); closeMenu(); }}>Home</li>
          <li onClick={() => { navigate('/MisNotas'); closeMenu(); }}>Mis Publicaciones</li>
          <li onClick={() => { navigate('/Editor'); closeMenu(); }}>Publicación Nueva</li>
        </ul>
      </div>
      
    </div>
  );
};

const styles = {
  topBar: {
    backgroundColor: '#FFD700',
    color: '#35464E',
    display: 'flex',
    flexDirection: 'column', // Organizar elementos en columnas
    padding: '10px 20px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  },
  navContainer: {
    marginBottom: '10px',
    paddingLeft: '5%',
  },
  navText: {
    fontSize: '1.2rem',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: '5%',
  },
  logoContent: {
    textAlign: 'left',
  },
  logo: {
    height: '35px',
    width: 'auto',
    marginRight: '10px',
    paddingLeft: '5%',
  },
  menuButton: {
    cursor: 'pointer',
    fontSize: '1.5rem',
    position: 'absolute',
    left: '10px',
    top: '10px',
  },
  menu: {
    listStyleType: 'none',
    padding: 0,
  },
  'menu-container': {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '20%',
    height: '100vh',
    backgroundColor: '#FFD700',
    transition: 'transform 0.3s ease-in-out',
    zIndex: 1000,
    transform: 'translateX(-100%)',
  },
  'menu-container.show': {
    transform: 'translateX(0)',
  },
  'menu li': {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #ccc',
  },
  'menu li:hover': {
    backgroundColor: '#F0E68C',
  },
  'close-menu': {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
};

export default TopBarDes;
