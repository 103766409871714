import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import swal from 'sweetalert2'; 
import { useNavigate } from 'react-router-dom'; 
import { FaTimes } from 'react-icons/fa';

const RichTextEditor = () => {
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelect, setcategoriaSelect] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState(null);
  const [url, setURL] = useState(null);
  const [descripcion,setDescripcion] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [tags, setTags] = useState('');
  const navigate = useNavigate(); 
  const id_user = localStorage.getItem('id_user');
  
  useEffect(() => {
    getCategorias();
  }, []);

  const handleSelectUser = (user) => {
    setSelectedUsers([...selectedUsers, user]);
    setTags('');
    setSearchResults([]);
  };

  const handleRemoveUser = (userToRemove) => {
    const updatedUsers = selectedUsers.filter(user => user.id !== userToRemove.id);
    setSelectedUsers(updatedUsers);
  };
  
  const handleChange = (e) => {
    const { value } = e.target;
    setTags(value);
    searchUsers(value);
  };

  const handleCategoria = (event) => {
    setcategoriaSelect(event.target.value);
  };
  
  const handleDescripcionChange = (event) => {
    setDescripcion(event.target.value);
  }; 

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  
  const handleURLChange = (event) => {
    setURL(event.target.value);
  };

  const getCategorias = () => {
    fetch('https://novomediaapp.azurewebsites.net/GetCategorias.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setCategorias(data.categorias);
        } else {
          console.error('Error al obtener las categorias:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const saveContent = async (status) => {
    const tagsString = selectedUsers.map(user => user.value).join(',');

    if (!title || !descripcion || !content) {
      swal.fire('Error', 'Por favor completa todos los campos obligatorios', 'error');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('descripcion', descripcion);
      formData.append('content', content);
      formData.append('userId', id_user);
      formData.append('categoria', categoriaSelect);
      formData.append('url', url);
      formData.append('status', status);
      formData.append('tags', tagsString);

      if (file) {
        formData.append('file', file);
      }

      const response = await fetch('https://novomediaapp.azurewebsites.net/GuardaArticulo.php', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          swal.fire({
            title: 'Publicación guardada',
            text: result.message,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Mis Publicaciones',
            cancelButtonText: 'Publicar Nueva Nota',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/MisNotas');
            } else {
              setTitle('');
              setcategoriaSelect(1);
              setDescripcion('');
              setContent('');
              setURL('');
              setFile(null);
            }
          });
        } else {
          swal.fire('Error',result.message, 'error');
        }
      } else {
        swal.fire('Error', 'Error en la solicitud', 'error');
      }
    } catch (error) {
      swal.fire('Error', 'Error de red', 'error');
    }
  };

  const searchUsers = (searchText) => {
    const data = {'type':'GetSearchUsers','texto':searchText}
    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      setSearchResults(data.data);
    })
    .catch(error => console.error('Error de red:', error));
  };

  return (
    <div className="container mt-4" style={{marginTop:'20%',marginBottom:'20%'}}>
      <div className="mb-3"><br/><br/>
        <label htmlFor="title" className="form-label"><b>*Título:</b></label>
        <input type="text" id="title" className="form-control" value={title} placeholder='Titulo' onChange={handleTitleChange} />
      </div>
      <div className="mb-3">
        <label htmlFor="descripcionCorta" className="form-label"><b>*Descripción Corta:</b></label>
        <input type="text" id="descripcionCorta" className="form-control" placeholder='Descripción Corta' value={descripcion} onChange={handleDescripcionChange} />
      </div>
      <div className="mb-3">
        <label htmlFor="categoria" className="form-label"><b>Categorias:</b></label>
        <select id="categoria" className="form-control" value={categoriaSelect} onChange={handleCategoria}>
          {categorias.map(categoria => (
            <option key={categoria.id} value={categoria.id}>{categoria.tipo}</option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="content" className="form-label"><b>*Publicación:</b></label>
        <ReactQuill
          value={content}
          defaultValue={'<p></p><p></p><p></p><p></p>'}
          onChange={handleContentChange}
          modules={RichTextEditor.modules}
          formats={RichTextEditor.formats}
          className="form-control"
        />
      </div>
      <br/>
      <div className="mb-3">
        <label htmlFor="file" className="form-label"><b>Adjuntar imagen:</b></label>
        <input 
          type="file" 
          id="file" 
          placeholder='Seleccionar Imagen'
          className="form-control" 
          accept="image/*" 
          onChange={handleFileChange} 
        />
      </div>
      <div className="mb-3">
        <label htmlFor="file" className="form-label"><b>Tags:</b></label>
        <input
          type="text"
          placeholder="Tags"
          value={tags}
          onChange={handleChange}
          className="form-control"
        />
      </div>
      <div style={{ top: '100%', left: '0', width: '100%', zIndex: '999', background: '#fff', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}>
        {searchResults.map((user, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '8px', cursor: 'pointer', borderBottom: '1px solid #eee' }} onClick={() => handleSelectUser(user)}>
            <img src={user.foto} alt={user.nick} style={{ width: '30px', height: '30px', borderRadius: '5%', marginRight: '8px' }} />
            <span style={{ fontSize: '14px' }}>{user.nick}</span>
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedUsers.map((user, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '4px', background: '#f0f0f0', borderRadius: '5px', marginRight: '4px', marginBottom: '4px' }}>
            <img src={user.foto} alt={user.nick} style={{ width: '20px', height: '20px', borderRadius: '5%', marginRight: '4px' }} />
            <span style={{ fontSize: '12px' }}>{user.nick}</span>
            <FaTimes style={{ marginLeft: '4px', cursor: 'pointer' }} onClick={() => handleRemoveUser(user)} />
          </div>
        ))}
      </div>
      <div className="mb-3">
        <label htmlFor="url" className="form-label"><b>URL Video:</b></label>
        <input type="text" id="url" placeholder='URL Video' className="form-control" value={url} onChange={handleURLChange} />
      </div>
      <div className="mb-3">
        <button onClick={() => saveContent(1)} className="btn btn-primary">Guardar Borrador</button>
        <button onClick={() => saveContent(2)} className="btn btn-primary">Publicar</button>
      </div>
    </div>
  );
};

RichTextEditor.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'},
     {'indent': '-1'}, {'indent': '+1'}],
    ['link'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
};

RichTextEditor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link'
];

export default RichTextEditor;
