import React, { useEffect, useState } from 'react';
import Publicaciones from './Publicaciones'

const PostList = () => {
  const [categorias, setCategorias] = useState([]);
  const [noticiasCategorias, setNoticiasCategorias] = useState([]);
  const [popularPost, setPopularPost] = useState(null);
  const [masLeidos, setmasLeidos] = useState([]);
  const [loUltimo, setLoUltimo] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [noticiasP, setNoticiasP] = useState(false);
  //const id_user = localStorage.getItem('id_user');
  
  const openPost = (post) => {
    setSelectedPost(post);
  };

  const closePost = () => {
    setSelectedPost(null);
    setImageError(false);
  };

  useEffect(() => {
    ultimosArticulos();
    popularPosteo();
    MasLeidos();
    PublicacionesSeccion();
    searchCategorias();
  }, []);

  const searchCategorias = () => {
    var data = {
      "id_user": 1
    };
    fetch('https://novomediaapp.azurewebsites.net/GetCategoriasUser.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setNoticiasCategorias([]);
          setCategorias(data.categorias);
          data.categorias.forEach((categoria) => {
            seccion(categoria.id);
          });
        } else {
          console.error('Error al obtener las categorias:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const ultimosArticulos = () => {
    var data = {
      "type": "GetPublicacionesUltimas",
      "n": 5
    };
    
    fetch('https://novomediaapp.azurewebsites.net/puntoLibre.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      setLoUltimo(data.data);
    })
    .catch(error => console.error('Error de red:', error));
  }

  const popularPosteo = () => {
    var data = {
      "type": "GetPopularPost", 
      'n':1
    };
    
    fetch('https://novomediaapp.azurewebsites.net/puntoLibre.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      setPopularPost(data.data);
    })
    .catch(error => console.error('Error de red:', error));
  }

  const MasLeidos = () => {
    var data = {
      "type": "GetPopularPost", 
      'n':3
    };
    
    fetch('https://novomediaapp.azurewebsites.net/puntoLibre.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      setmasLeidos(data.data);
    })
    .catch(error => console.error('Error de red:', error));
  }

  const PublicacionesSeccion = () => {
    var data = {
      "type": "GetPublicaciones", 
      'categoria':17,
      'n':3
    };
    
    fetch('https://novomediaapp.azurewebsites.net/puntoLibre.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      setNoticiasP(data.data);
    })
    .catch(error => console.error('Error de red:', error));
  }

  const handleImageError = () => {
    setImageError(true);
  };

  const calcularTiempoPasado = (fecha) => {
    const fechaPublicacion = new Date(fecha);
    const fechaActual = new Date();

    const diferencia = fechaActual - fechaPublicacion;
    const segundos = Math.floor(diferencia / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);

    return { dias, horas: horas % 24, minutos: minutos % 60 };
  };

  const seccion = (seccion) => {
    var data = {
      "type": "GetPublicaciones",
      "categoria": seccion,
      'n':3
    };
    
    fetch('https://novomediaapp.azurewebsites.net/puntoLibre.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if(data.success){
        setNoticiasCategorias(prevPosts => [...prevPosts, data.data]);
      }
    })
    
    .catch(error => console.error('Error de red:', error));
  }

  return (
    <div className='row' style={{ margin: '3%' }}>
      <br/>
      {selectedPost ? (
        <div style={{paddingLeft:'10%',paddingRight:'10%'}}>
          <button onClick={closePost} className='btn btn-info'>Regresar</button>
          <div>
            <br/>
            <h2>{selectedPost.titulo}</h2>
            <h5>{selectedPost.descripcionCorta}</h5>
            {imageError ? (
              ''
            ) : (
              <img
                src={`https://novomediaapp.azurewebsites.net/assents/publicaciones/${selectedPost.id}.jpg`}
                alt="Imagen principal"
                onError={handleImageError}
                style={{ height: 'auto', width: '100%'}}
              />
            )}
            <div dangerouslySetInnerHTML={{ __html: selectedPost.articulo }} />
            <i>Autor | {selectedPost.autor}</i><br/>
            <i>{`Hace ${calcularTiempoPasado(selectedPost.fecha).dias} días, ${calcularTiempoPasado(selectedPost.fecha).horas} horas y ${calcularTiempoPasado(selectedPost.fecha).minutos} minutos`}</i>
          </div>
        </div>
      ) : (
        <>
          <div className="col-md-3" style={{ maxHeight: '800px', overflowY: 'auto' }}>
            <Publicaciones publicacion={loUltimo} titulo={'Lo ultimo'} openPost={openPost} tipo={2} calcularTiempoPasado={calcularTiempoPasado}/>
          </div>
          <div className="col-md-6">
            <Publicaciones publicacion={popularPost} titulo={'Lo mas popular'} openPost={openPost} tipo={1} calcularTiempoPasado={calcularTiempoPasado}/>
            <div className='row'>
              {noticiasP && noticiasP.map((noticia, index) => (
                <div className="col-md-4" onClick={() => openPost(noticia)}>
                  <div key={index} style={{paddingBottom:'3vh'}}>
                    <div className="card-body">
                      <i className="card-text" style={{color:'#7E6640'}}>Autor | {noticia.autor}</i><br/>
                      <i className="card-text">{noticia.seccion}</i>
                      <h5 className="card-title">{noticia.titulo}</h5>
                      <i>{`Hace ${calcularTiempoPasado(noticia.fecha).dias} días, ${calcularTiempoPasado(noticia.fecha).horas} horas y ${calcularTiempoPasado(noticia.fecha).minutos} minutos`}</i>
                    </div>
                  </div>  
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3" style={{ maxHeight: '800px', overflowY: 'auto' }}>
            <Publicaciones publicacion={masLeidos} titulo={'Lo mas leído'} openPost={openPost} tipo={2} calcularTiempoPasado={calcularTiempoPasado}/>
          </div>
          <div className='row'>
            {noticiasCategorias && noticiasCategorias.map((noticia, index) => (
              <React.Fragment>
                <h1>{noticia[0].seccion}</h1>
                {noticia && noticia.map((noti, index) => (
                  <div className="col-md-4" onClick={() => openPost(noti)}>
                    <div key={index} style={{paddingBottom:'3vh'}}>
                      <div className="card-body">
                        <i className="card-text" style={{color:'#7E6640'}}>Autor | {noti.autor}</i><br/>
                        <i className="card-text">{noti.seccion}</i>
                        <h5 className="card-title">{noti.titulo}</h5>
                        <i>{`Hace ${calcularTiempoPasado(noti.fecha).dias} días, ${calcularTiempoPasado(noti.fecha).horas} horas y ${calcularTiempoPasado(noti.fecha).minutos} minutos`}</i>
                      </div>
                    </div>  
                  </div>
                ))}
                <hr/>
              </React.Fragment>
            ))}
          </div>
        </>
      )
    }      
    </div>
  );
};

export default PostList;
