import React, { useEffect,useState } from 'react';
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import EditarNota from './EditarNota';
import TopBarDes from './TopBarDes';
import Editor from './Editor';
import Categorias from './Categorias';
import PublicacionDes from './PublicacionDes';
import MisNotas from './MisNotas';

const App = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isValidSession, setIsValidSession] = useState(false);
  const [nota, setNota] = useState([]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    setIsDesktop(mediaQuery.matches);
    const urlParams = new URLSearchParams(window.location.search);
    const sessionToken = urlParams.get('sessionToken');
    const id_user = localStorage.getItem('id_user');
    const token = localStorage.getItem('token');
    
    if (sessionToken) {
      getSesion(sessionToken);
    }else{
      if(token && id_user){
        setIsValidSession(true);  
      } else {
        localStorage.setItem('token', '');
        localStorage.setItem('id_user', '');
        window.location.href = 'https://deemby.org/';
      }
    }
    
  }, []);

  const getSesion = (token) => {
    localStorage.setItem('token', '');
    localStorage.setItem('id_user', '');
    localStorage.setItem('organizacion', '');

    const data = {'type':'GetSesion',token}
    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if(data.success){
        console.log(data);
        localStorage.setItem('token', data.token);
        localStorage.setItem('id_user', data.usuario_id);
        localStorage.setItem('organizacion', data.organizacion_id);
        setIsValidSession(true);
      }else{
        setIsValidSession(false);
      }
      
    })
    .catch(error => console.error('Error de red:', error));
  }  

  return (
    <div>
      {isValidSession?
        <Router>
          <TopBarDes/>
          <Routes>
            <Route path="/" element={<Categorias/>}/>
            <Route path="/Editor" element={<Editor/>}/>
            <Route path="/MisNotas" element={<MisNotas setNota={setNota}/>}/>
            <Route path="/MisPublicaciones" element={<PublicacionDes />}/>
            <Route path="/EditarNota" element={<EditarNota nota={nota}/>}/>
          </Routes>
        </Router> 
       :<p>La sesión no es válida. Serás redirigido a otra página.</p>
      }
    </div>
  );
};

export default App;
