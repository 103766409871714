import React, {useState} from 'react';

const PostList = ({ publicacion, titulo,openPost,tipo,calcularTiempoPasado }) => {
  const [imageError, setImageError] = useState(false);
  const handleImageError = () => {
    setImageError(true);
  };
  

  return (
    <div >
      <br/><br/>
      <h4>{titulo}</h4>
      {publicacion &&publicacion.map((post, index) => (
        <div className='row' key={index}>
          <div key={index} onClick={() => openPost(post)} className="col-md-12">
            <div className='row' key={index} style={{ display: 'flex' }}>
            {tipo===1?
              imageError ? (
                ''
              ) : (
              <div className="col-md-12">
                <img src={`https://novomediaapp.azurewebsites.net/assents/publicaciones/${post.id}.jpg`} 
                alt="Imagen de la publicación" 
                onError={handleImageError}
                style={{ width: '100%' }} />
              </div>
              )
            :''}
              <div style={{paddingBottom:'3vh'}}> 
                <div className="card-body">
                  <i className="card-text" style={{color:'#7E6640'}}>Autor | {post.autor}</i><br/>
                  <i className="card-text">{post.seccion}</i>
                  <h5 className="card-title">{post.titulo}</h5>
                  <i>{`Hace ${calcularTiempoPasado(post.fecha).dias} días, ${calcularTiempoPasado(post.fecha).horas} horas y ${calcularTiempoPasado(post.fecha).minutos} minutos`}</i>
                </div>
              </div>
            </div>
          </div>
          {tipo!==1?
          <hr/>
          :''
          }
        </div>
      ))}
    </div>
  );
};

export default PostList;
