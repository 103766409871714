import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 

const MisNotas = ({setNota}) => {
  const id_user = localStorage.getItem('id_user');
  const [notas, setNotas] = useState([]);
  const navigate = useNavigate(); 

  useEffect(() => {
    if (id_user) {
      misPublicaciones();
    }
  }, [id_user]);

  const editaNota = (nota) => {
    setNota(nota);
    navigate('/EditarNota');
  }

  const misPublicaciones = () => {
    var data = {
      "id_user": id_user,
      'type':'GetNotas'
    };
    fetch('https://novomediaapp.azurewebsites.net/puntoLibre.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setNotas(data.data);
          console.log(data.data);
        } else {
          console.error('Error al obtener las notas:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  return (
    <div className="container mt-3" style={{ marginTop: '10%' }}>
      <h2>Mis Publicaciones</h2>
      <ul>
        {notas.map(nota => (
          <div key={nota.id} style={{ border: '1px solid black', marginBottom: '10px', padding: '10px' }}>
            <h3>{nota.titulo} 
              <button className='btn btn-primary' onClick={() => editaNota(nota)} hidden={nota.status==='1'?false:true}> 
                Editar nota
              </button>
            </h3>
            <h5>{nota.descripcionCorta}</h5>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <div dangerouslySetInnerHTML={{ __html: nota.nota }} />
            </div>
            <img src={'https://novomediaapp.azurewebsites.net/assents/publicaciones/'+nota.id+'.jpg'} style={{height:'auto',width:'50%'}}/>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default MisNotas;
